import { Chip, ChipProps, createStyles, withStyles, WithStyles } from '@material-ui/core';
import clsx from 'clsx';
import React, { FunctionComponent } from 'react';

const styles = createStyles({
    root: {
        marginTop: '.5rem',
        marginBottom: '.3rem',
        backgroundColor: 'white',
        '&.MuiChip-clickable:hover': { backgroundColor: '#f9f9f9' },
    },
});

export type AutocompleteChipProps = Omit<ChipProps, 'variant' | 'css'>;

const AutocompleteChip = withStyles(styles)((
    { classes, ...otherProps }: ChipProps & WithStyles<typeof styles>,
) => (
    <Chip
        {...otherProps}
        variant="outlined"
        className={clsx(otherProps.className, classes.root)}
    />
)) as FunctionComponent<AutocompleteChipProps>;

export default AutocompleteChip;
