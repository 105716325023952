import {
    FilledTextFieldProps as MuiTextFieldProps,
    makeStyles, TextField as MuiTextField,
} from '@material-ui/core';
import clsx from 'clsx';
import React, { FunctionComponent } from 'react';

export type TextFieldProps = Omit<MuiTextFieldProps, 'variant'> & {
    noHelperTextSpace?: boolean;
    label: string;
    fixedHelperText?: boolean;
};

const useStyles = makeStyles({
    root: { marginBottom: '.25rem' },
    fixedHelperText: { marginBottom: '1rem' },
});

const TextField: FunctionComponent<TextFieldProps> = props => {
    const classes = useStyles();

    const {
        noHelperTextSpace,
        className,
        helperText,
        fixedHelperText,
        ...otherProps
    } = props;

    return (
        <MuiTextField
            fullWidth
            {...otherProps}
            variant="filled"
            // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
            helperText={helperText || (noHelperTextSpace ? '' : ' ')}
            className={
                clsx(
                    classes.root,
                    { [classes.fixedHelperText]: fixedHelperText },
                    className,
                )
            }
        />
    );
};

export default TextField;
