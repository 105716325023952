import { Button, Menu as MaterialMenu } from '@material-ui/core';
import React, { FunctionComponent, useRef } from 'react';
import useRandomId from '../helpers/useRandomId';
import menuButtonStyles from './MenuButton.styles';

export interface MenuButtonProps {
    open: boolean;
    onClick: () => void;
    onClose: () => void;
    menuItems: JSX.Element[] | JSX.Element;
    className?: string;
}

const MenuButton: FunctionComponent<MenuButtonProps> = ({
    open,
    onClick,
    onClose,
    menuItems,
    children,
    className,
}) => {
    const id = useRandomId();
    const classes = menuButtonStyles();
    const buttonRef = useRef(null);
    const anchorEl = open ? buttonRef.current : null;

    return (
        <div className={classes.menuContainer}>
            <Button
                aria-controls={id}
                aria-haspopup="true"
                onClick={onClick}
                ref={buttonRef}
                className={className}
            >
                {children}
            </Button>

            <MaterialMenu
                id={id}
                className={classes.menu}
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={onClose}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                {menuItems}
            </MaterialMenu>
        </div>
    );
};

export default MenuButton;
