import { StepLabel as MuiStepLabel, StepLabelProps, styled } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import StepIcon from './StepIcon';

const StyledStepLabel = styled(MuiStepLabel)({
    '& .MuiStepLabel-active': { color: 'rgba(0, 0, 0, 0.54)' },
});

const StepLabel: FunctionComponent<{
    children: StepLabelProps['children'];

    // TODO: Remove after demo
    onClick?: () => void;
    onContextMenu?: StepLabelProps['onContextMenu'];
}> = ({
    children,

    // Note: Other props will be provided by the Step parent component
    ...otherProps
}) => (
    <StyledStepLabel StepIconComponent={StepIcon} {...otherProps}>
        {children}
    </StyledStepLabel>
);

export default StepLabel;
