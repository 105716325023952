import { Fade, Grid, makeStyles } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import PageLoader from './PageLoader';

const useStyles = makeStyles({
    loaderContainer: { minHeight: '100vh' },
    loaderItem: { width: '100%' },
    loader: {
        width: '60%',
        margin: '0 auto',
    },
});

const FullScreenLoader: FunctionComponent = () => {
    const classes = useStyles();

    return (
        <Fade in>
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                className={classes.loaderContainer}
            >
                <Grid item xs={12} className={classes.loaderItem}>
                    <PageLoader className={classes.loader}/>
                </Grid>
            </Grid>
        </Fade>
    );
};

export default FullScreenLoader;
