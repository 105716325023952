import {
    createStyles,
    makeStyles,
} from '@material-ui/core';

const useModalStyles = makeStyles(theme => createStyles({
    root: { display: 'flex' },
    content: {
        padding: theme.spacing(4),
        '& h2': {
            marginTop: 0,
            lineHeight: '1.5em',
            [theme.breakpoints.up('sm')]: { lineHeight: '29px' },
        },
        '& p': { margin: 0 },
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
        '& [class*="MuiSvgIcon-root"]': {
            width: '0.75em',
            height: '0.75em',
        },
    },
}));

export default useModalStyles;
