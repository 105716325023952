import { makeStyles } from '@material-ui/core';
import MuiAlert, { Color } from '@material-ui/lab/Alert';
import clsx from 'clsx';
import React, { FunctionComponent } from 'react';

export type Severity = Color;

export interface AlertProps {
    severity: Severity;
    inline?: boolean;
    className?: string;
}

const useStyles = makeStyles({ inline: { display: 'inline-flex' } });

const Alert: FunctionComponent<AlertProps> = (
    { severity, children, inline, className },
) => {
    const classes = useStyles();

    return (
        <MuiAlert
            severity={severity}
            className={
                clsx(
                    { [classes.inline]: inline },
                    className,
                )
            }
        >
            {children}
        </MuiAlert>
    );
};

export default Alert;
