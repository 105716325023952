import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { CLOSED_DRAWER_WITH, DRAWER_WIDTH } from './NavigationBar.styles';

const useTopBarStyles = makeStyles((theme: Theme) => createStyles({
    appBar: {
        zIndex: theme.zIndex.drawer,
        background: theme.palette.common.white,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        [theme.breakpoints.up('sm')]: { width: `calc(100% - ${CLOSED_DRAWER_WITH}px)` },
    },
    appBarShift: {
        marginLeft: DRAWER_WIDTH,
        width: `calc(100% - ${DRAWER_WIDTH}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
}));

export default useTopBarStyles;
