import { makeStyles } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
    root: {
        textDecoration: 'none',
        color: '#828282',
        fontWeight: 600,
        fontSize: '1rem',
    },
});

export interface TertiaryButtonLinkProps {
    to: string;
}

const TertiaryButtonLink: FunctionComponent<TertiaryButtonLinkProps> = ({
    children,
    to,
}) => {
    const classes = useStyles();

    return (
        <Link
            to={to}
            className={classes.root}
        >
            {children}
        </Link>
    );
};

export default TertiaryButtonLink;
