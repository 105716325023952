import { makeStyles, TableCell } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import React, { FunctionComponent } from 'react';
import MenuButton, { MenuButtonProps } from '../buttons/MenuButton';

const useStyles = makeStyles({
    root: {
        padding: '0 1rem',
    },
});

const OptionsTableCell: FunctionComponent<{
    menuOpen: boolean;
    setMenuOpen: (isOpen: boolean) => void;
    children: MenuButtonProps['menuItems'];
}> = ({ children, menuOpen, setMenuOpen }) => {
    const classes = useStyles();

    return (
        <TableCell
            className={classes.root}
            align="right"
        >
            <MenuButton
                open={menuOpen}
                onClick={() => setMenuOpen(true)}
                onClose={() => setMenuOpen(false)}
                menuItems={children}
            >
                <MoreVertIcon/>
            </MenuButton>
        </TableCell>
    );
};

export default OptionsTableCell;
