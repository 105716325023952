import { Button, Fade, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React, { FunctionComponent } from 'react';
import useLoaderDebounce from '../helpers/useLoaderDebounce';
import Spinner from '../Spinner';

export interface SecondaryButtonProps {
    onClick: () => void;
    disabled?: boolean;
    loading?: boolean;
    className?: string;
    small?: boolean;
    variation?: 'danger';
}

const useStyles = makeStyles(theme => ({
    spinner: {
        marginLeft: '.5rem',
    },
    danger: {
        borderColor: theme.palette.error.main,
        color: theme.palette.error.main,
        '&:hover': {
            background: 'rgba(128, 24, 24, 0.04)',
            borderColor: theme.palette.error.dark,
        },
    },
}));

const SecondaryButton: FunctionComponent<SecondaryButtonProps> = (
    { children, onClick, disabled, className, loading, small, variation },
) => {
    const classes = useStyles();
    const showSpinner = useLoaderDebounce(loading ?? false);

    return (
        <Button
            onClick={onClick}
            color="primary"
            className={clsx({
                [classes.danger]: variation === 'danger',
            }, className)}
            variant="outlined"
            // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
            disabled={disabled || loading}
            type="button"
            size={small ? 'small' : undefined}
        >
            {children}

            {
                showSpinner
                    ? (
                        <Fade in>
                            <Spinner size={20} className={classes.spinner} />
                        </Fade>
                    )
                    : null
            }
        </Button>
    );
};

export default SecondaryButton;
