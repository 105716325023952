import {
    Collapse,
    createStyles, IconButton, makeStyles,
    TextField, Toolbar, Tooltip, Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import FilterIcon from '@material-ui/icons/FilterList';
import SearchIcon from '@material-ui/icons/Search';
import React, { FunctionComponent, ReactElement, useState } from 'react';
import getValue from '../helpers/getValue';

const useStyles = makeStyles(theme => createStyles({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        borderBottom: '1px solid #e0e0e0',
    },
    title: {
        flex: '1 1 100%',
        margin: 0,
    },
    searchField: {
        maxWidth: '25ch',
        marginRight: '.5rem',
    },
    filterRow: {
        borderBottom: '1px solid #e0e0e0',
        padding: '.5rem',
    },
    chipWrapper: {
        display: 'inline-block',
        verticalAlign: 'middle',
        marginRight: '.25rem',
    },
}));

const TableHeader: FunctionComponent<{
    title: string;
    onSearchChange?: (value: string) => void;
    filters?: {
        enabled: boolean;
        onEnable: () => void;
        onDisable: () => void;
        chips: ReactElement[];
    };
}> = ({ title, onSearchChange, filters }) => {
    const classes = useStyles();

    // TODO: Translate

    return (
        <>
            <Toolbar className={classes.root}>
                <Typography
                    className={classes.title}
                    color="inherit"
                    variant="h2"
                    component="div"
                >
                    {title}
                </Typography>

                {getValue(() => {
                    if (!filters) {
                        return null;
                    }

                    return filters.enabled
                        ? (
                            <Tooltip title="Hide filters">
                                <IconButton
                                    aria-label="Hide filters"
                                    onClick={() => filters.onDisable()}
                                    color="primary"
                                >
                                    <FilterIcon />
                                </IconButton>
                            </Tooltip>
                        )
                        : (
                            <Tooltip title="Show filters">
                                <IconButton
                                    aria-label="Show filters"
                                    onClick={() => filters.onEnable()}
                                >
                                    <FilterIcon />
                                </IconButton>
                            </Tooltip>
                        );
                })}
                {
                    onSearchChange
                        ? (
                            <HeaderSearch
                                onSearchChange={onSearchChange}
                            />
                        )
                        : null
                }
                {

                }

            </Toolbar>

            {
                filters
                    ? (
                        <Collapse in={filters.enabled}>
                            <div className={classes.filterRow}>
                                {
                                    filters.chips.map((chip, index) => (
                                        <div key={index} className={classes.chipWrapper}>
                                            {chip}
                                        </div>
                                    ))
                                }
                            </div>
                        </Collapse>
                    )
                    : null
            }
        </>
    );
};

const HeaderSearch: FunctionComponent<{ onSearchChange: (value: string) => void }>
    = ({ onSearchChange }) => {
        const [searchBarOpen, setSearchBarOpen] = useState(false);
        const [searchValue, setSearchValue] = useState('');
        const classes = useStyles();

        return (
            searchBarOpen
                ? (
                    <>
                        <TextField
                            variant="outlined"
                            label="Search"
                            fullWidth
                            size="small"
                            value={searchValue}
                            onChange={event => {
                                const newValue = event.target.value;

                                setSearchValue(newValue);
                                onSearchChange(newValue);
                            }}
                            className={classes.searchField}
                            autoFocus
                            onBlur={() => {
                                if (searchValue.trim() === '') {
                                    setSearchBarOpen(false);
                                }

                                onSearchChange(searchValue);
                            }}
                        />

                        <Tooltip title="Close search">
                            <IconButton
                                aria-label="Close search"
                                onClick={() => {
                                    setSearchBarOpen(false);
                                    setSearchValue('');
                                    onSearchChange('');
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Tooltip>
                    </>
                )
                : (
                    <Tooltip title="Search">
                        <IconButton
                            aria-label="Search in list"
                            onClick={() => setSearchBarOpen(true)}
                        >
                            <SearchIcon />
                        </IconButton>
                    </Tooltip>
                )
        );
    };

export default TableHeader;
