import { Button, makeStyles } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import React, { FunctionComponent } from 'react';

export interface ArrowButtonProps {
    onClick: () => void;
    children: string;
}

const useStyles = makeStyles(theme => ({
    root: {
        color: theme.palette.common.black,
        fontWeight: 'normal',
        margin: 0,
        padding: 0,
        textAlign: 'left',
        '&:hover': {
            backgroundColor: 'transparent',
            color: '#000',
        },
    },
}));

const ArrowButton: FunctionComponent<ArrowButtonProps> = ({ onClick, children }) => {
    const classes = useStyles();

    return (
        <Button
            onClick={onClick}
            disableRipple
            disableFocusRipple
            disableTouchRipple
            className={classes.root}
        >
            {children}

            <ArrowForwardIcon color="secondary"/>
        </Button>
    );
};

export default ArrowButton;
