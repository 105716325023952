import { Snackbar } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Alert, { Color } from '@material-ui/lab/Alert';
import React, { FunctionComponent } from 'react';

export type Severity = Color;

export interface NotificationProps {
    severity: Severity;
    onClose: () => void;
}

const Notification: FunctionComponent<NotificationProps> = (
    { severity, onClose, children },
) => (
    <Snackbar
        open={true}
        autoHideDuration={5000}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        disableWindowBlurListener={true}
        onClose={(_event, reason) => {
            if (reason !== 'clickaway') {
                onClose();
            }
        }}
    >
        <Alert
            variant="filled"
            iconMapping={{ success: <CheckCircleOutlineIcon fontSize="inherit" /> }}
            severity={severity}
            onClose={onClose}
        >
            {children}
        </Alert>
    </Snackbar>
);

export default Notification;
