import {
    Chip, Drawer, Grid, IconButton,
    useMediaQuery, useTheme,
} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import clsx from 'clsx';
import React, { FunctionComponent, ReactNode } from 'react';
import getValue from '../../helpers/getValue';
import useNavigationBarStyles from './NavigationBar.styles';

export interface NavigationBarProps {
    closed: boolean;
    onMenuClose: () => void;
    onMenuOpen: () => void;
    footer?: ReactNode;

    /**
     * @deprecated Use `environmentBadge` instead.
     */
    isDevEnvironment?: boolean;

    /**
     * Text to show as a badge to indicate the current environment.
     * Should not be used on production.
     */
    environmentBadge?: string;
}

const NavigationBar: FunctionComponent<NavigationBarProps> = ({
    closed, onMenuOpen: openMenu,
    onMenuClose: closeMenu, isDevEnvironment, environmentBadge, children,
    footer,
}) => {
    const classes = useNavigationBarStyles();
    const theme = useTheme();
    const mobileView = useMediaQuery(theme.breakpoints.down('xs'));

    const closeOnMobile = (): void => {
        if (mobileView) {
            closeMenu();
        }
    };

    return (
        <Drawer
            className={clsx(classes.drawer, {
                [classes.drawerOpen]: !closed,
                [classes.drawerClose]: closed,
            })}
            classes={{
                paper: clsx({
                    [classes.drawerOpen]: !closed,
                    [classes.drawerClose]: closed,
                }),
            }}
            variant="permanent"
            anchor="left"
            open={!closed}
        >
            <div className={classes.toolbar}>
                {getValue(() => {
                    const label = getValue((): string | null => {
                        if (environmentBadge) {
                            return environmentBadge;
                        }

                        if (isDevEnvironment) {
                            return 'Development';
                        }

                        return null;
                    });

                    return label
                        ? <Chip label={label} />
                        : null;
                })}

                {closed ? (
                    <IconButton
                        onClick={openMenu}
                        title="menu-open"

                        // TODO: Translate
                        aria-label="Open menu"
                    >
                        <ChevronRightIcon />
                    </IconButton>
                ) : (
                    <IconButton
                        onClick={closeMenu}
                        title="menu-close"

                        // TODO: Translate
                        aria-label="Close menu"
                    >
                        <ChevronLeftIcon />
                    </IconButton>
                )}
            </div>

            <Grid
                container
                direction="column"
                justify="space-between"
            >
                <Grid item>
                    <div onClick={() => closeOnMobile()}>
                        {children}
                    </div>
                </Grid>

                {footer}
            </Grid>
        </Drawer>
    );
};

export default NavigationBar;
