import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

interface SecondaryTabProps {
    url: string;
    index: number;
    selectedIndex: number;
    className?: string;
}

const useStyles = makeStyles(theme => ({
    root: {
        padding: '.5rem 1rem',
        color: '#788B9B',
        borderRadius: 4,
        textDecoration: 'none',
        fontWeight: 'bold',
        display: 'inline-block',
    },
    selected: {
        backgroundColor: '#dcdff3',
        color: theme.palette.primary.main,
    },
}));

const SecondaryTab: FunctionComponent<SecondaryTabProps> = ({
    url,
    index,
    selectedIndex,
    children,
    className,
}) => {
    const classes = useStyles();

    return (
        <Link
            to={url}
            className={clsx(
                classes.root,
                { [classes.selected]: index === selectedIndex },
                className,
            )}
        >
            {children}
        </Link>
    );
};

export default SecondaryTab;
