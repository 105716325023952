import React, { FormHTMLAttributes, FunctionComponent } from 'react';

export interface FormProps extends FormHTMLAttributes<HTMLFormElement> {
    onSubmit: () => void;
}

/**
 * This component should be used instead of <form>. It ensures that onSubmit
 * is provided and prevents submitting to reload the page, which is the default behaviour.
 */
const Form: FunctionComponent<FormProps> = ({ onSubmit, children, ...otherProps }) => (
    <form
        onSubmit={event => {
            event.preventDefault();
            onSubmit();
        }}
        {...otherProps}
    >
        {children}
    </form>
);

export default Form;
