import {
    createStyles,
    Tabs as MuiTabs,
    withStyles,
} from '@material-ui/core';
import React, { FunctionComponent } from 'react';

const StyledTabs = withStyles(theme => createStyles({
    root: { borderBottom: `1px solid ${theme.palette.grey[500]}` },
    indicator: { backgroundColor: theme.palette.primary.main },
}))(MuiTabs);

export interface TabsProps {
    value: number;
    className?: string;
    onChange?: (index: number) => void;
}

const Tabs: FunctionComponent<TabsProps> = ({ value, children, className, onChange }) => (
    <StyledTabs
        value={value}
        variant="scrollable"
        scrollButtons="auto"
        className={className}
        onChange={(_event, newValue) => onChange?.(newValue as number)}
    >
        {children}
    </StyledTabs>
);

export default Tabs;
