import React, { FunctionComponent } from 'react';
import TimeField, { TimeFieldProps } from './TimeField';

const UtcTimeField: FunctionComponent<Omit<TimeFieldProps, 'format' | 'utc'>> = props => (
    <TimeField
        {...props}
        utc
        format="HH:mm"
    />
);

export default UtcTimeField;
