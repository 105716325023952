import {
    AppBar,
    Grid,
    Toolbar,
} from '@material-ui/core';
import clsx from 'clsx';
import React, { FunctionComponent } from 'react';
import useTopBarStyles from './TopBar.styles';

export interface TopBarProps {
    closed: boolean;
}

const TopBar: FunctionComponent<TopBarProps> = ({ closed, children }) => {
    const classes = useTopBarStyles();

    return (
        <AppBar
            position="fixed"
            className={clsx(classes.appBar, { [classes.appBarShift]: !closed })}
        >
            <Toolbar>
                <Grid
                    container
                    justify="space-between"
                    alignItems="center"
                >
                    {children}
                </Grid>
            </Toolbar>
        </AppBar>
    );
};

export default TopBar;
