import {
    ThemeProvider as MaterialThemeProvider,
    StylesProvider,
} from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import theme from './styles/theme';

const ThemeProvider: FunctionComponent = ({ children }) => (
    <StylesProvider>
        <MaterialThemeProvider theme={theme}>
            {children}
        </MaterialThemeProvider>
    </StylesProvider>
);

export default ThemeProvider;
