import CircularProgress, { CircularProgressProps }
    from '@material-ui/core/CircularProgress';
import React, { FunctionComponent } from 'react';

export interface SpinnerProps {
    size: number;
    color?: CircularProgressProps['color'];
    disableShrink?: boolean;
    className?: string;
}

// TODO: Predefined semantic sizes

const Spinner: FunctionComponent<SpinnerProps> = (
    { size, color, disableShrink, className },
) => (
    <CircularProgress
        size={size}
        color={color ?? 'inherit'}
        thickness={4}
        disableShrink={disableShrink}
        className={className}
    />
);

export default Spinner;
