import {
    FormControl, FormHelperText,
    InputLabel, Select as MuiSelect,
} from '@material-ui/core';
import React, { ReactNode } from 'react';
import useRandomId from '../helpers/useRandomId';

export interface SelectProps<T> {
    label: string;
    children: ReactNode;
    value: T;
    onChange: (newValue: T) => void;
    helperText?: string;
    error?: boolean;
    required?: boolean;
}

export default function Select<T>({
    label,
    children,
    value,
    onChange,
    helperText,
    error,
    required,
}: SelectProps<T>): JSX.Element {
    const labelId = useRandomId();

    return (
        <FormControl fullWidth variant="filled">
            <InputLabel
                id={labelId}
                error={error}
                required={required}
            >
                {label}
            </InputLabel>

            <MuiSelect
                variant="filled"
                labelId={labelId}
                value={value}
                onChange={event => onChange(event.target.value as T)}
                fullWidth
                error={error}
                required={required}
            >
                {children}
            </MuiSelect>

            <FormHelperText error={error}>
                {/* eslint-disable-next-line max-len */}
                {/* eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing, @typescript-eslint/no-unnecessary-condition */}
                {helperText || ' '}
            </FormHelperText>
        </FormControl>
    );
}
