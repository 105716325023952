import {
    createStyles,
    Tabs as MuiTabs,
    withStyles,
} from '@material-ui/core';
import React, { FunctionComponent } from 'react';

const StyledTabs = withStyles(theme => createStyles({
    root: { borderBottom: `1px solid ${theme.palette.grey[500]}` },
    indicator: {
        backgroundColor: theme.palette.primary.main,
        padding: `0 ${theme.spacing(3)}px`,
    },
}))(MuiTabs);

export interface NumberedTabsProps {
    value: number;
    className?: string;
}

const NumberedTabs: FunctionComponent<NumberedTabsProps> = ({
    value, children, className,
}) => (
    <StyledTabs
        value={value}
        variant="scrollable"
        scrollButtons="auto"
        className={className}
    >
        {children}
    </StyledTabs>
);

export default NumberedTabs;
