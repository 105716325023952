import { Typography } from '@material-ui/core';
import { Variant } from '@material-ui/core/styles/createTypography';
import React, { ElementType, FunctionComponent } from 'react';

export interface HeadingProps {
    level: 1|2|3|4;
}

const Heading: FunctionComponent<HeadingProps> = ({ children, level }) => (
    <Typography
        variant={`h${level}` as Variant}
        component={`h${level}` as ElementType}
    >
        {children}
    </Typography>
);

export default Heading;
