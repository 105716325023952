import { LinearProgress, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React, { FunctionComponent } from 'react';
import logo from './images/logoLightBackground.svg';

const useStyles = makeStyles({
    container: { maxWidth: '200px' },
    logo: { display: 'block' },
    progressBar: { marginTop: '1.5rem' },
});

const PageLoader: FunctionComponent<{className?: string}> = ({ className }) => {
    const classes = useStyles();

    return (
        <div className={clsx(classes.container, className)}>
            <img
                src={logo}

                // TODO: Translate
                alt="Loading Get-E portal"
                className={classes.logo}
            />

            <LinearProgress className={classes.progressBar} />
        </div>
    );
};

export default PageLoader;
