import { Box, createStyles, makeStyles } from '@material-ui/core';
import React from 'react';

export interface TabPanelProps {
    children?: React.ReactNode;
    value: number;
    selectedValue: number;
}

const useStyles = makeStyles(() => createStyles({ fullWidth: { width: '100%' } }));

const TabPanel = ({ selectedValue, value, children }: TabPanelProps): JSX.Element => {
    const classes = useStyles();

    return (
        <div
            role="tabpanel"
            hidden={value !== selectedValue}
            id={`tabpanel-${value}`}
            aria-labelledby={`tab-${value}`}
            className={classes.fullWidth}
        >
            <Box>{children}</Box>
        </div>
    );
};

export default TabPanel;
