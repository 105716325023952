import { StepConnector as MuiStepConnector, withStyles } from '@material-ui/core';

const StepConnector = withStyles({
    alternativeLabel: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    completed: {
        '& $line': {
            // TODO: Move to theme
            borderColor: '#388e3c',
        },
    },
    line: {
        borderColor: '#eaeaf0',
        borderTopWidth: 3,
        borderRadius: 1,
    },
})(MuiStepConnector);

export default StepConnector;
