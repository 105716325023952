import { makeStyles, Typography } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import PrimaryButton from './buttons/PrimaryButton';
import Modal from './Modal';

const useStyles = makeStyles({
    buttonContainer: { marginTop: '1rem' },
    header: { marginBottom: '1rem' },
    heading: { margin: 0 },
    primaryButton: { marginRight: '.5rem' },
});

export interface MessageDialogProps {
    title: string;
    onClose: () => void;
}

const MessageDialog: FunctionComponent<MessageDialogProps> = ({
    title, onClose, children,
}) => {
    const { t } = useTranslation();
    const modalClasses = useStyles();

    return (
        <Modal
            open={true}
            onClose={onClose}
        >
            <div className={modalClasses.header}>
                <Typography variant="h2" component="h2" className={modalClasses.heading}>
                    {title}
                </Typography>
            </div>

            <div>{children}</div>

            <div className={modalClasses.buttonContainer}>
                <span className={modalClasses.primaryButton}>
                    <PrimaryButton onClick={onClose}>
                        {t('buttons.closeDialog')}
                    </PrimaryButton>
                </span>
            </div>
        </Modal>
    );
};

export default MessageDialog;
