import { Grid } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import useNavigationBarStyles from './NavigationBar.styles';
import SideBarLogo from './SideBarLogo';

const NavigationBarFooter: FunctionComponent<{closed: boolean}> = ({ closed }) => {
    const classes = useNavigationBarStyles();

    return (
        <Grid item className={classes.companyInformationContainer}>
            <SideBarLogo closed={closed}/>
        </Grid>
    );
};

export default NavigationBarFooter;
