import { useMemo } from 'react';

export default function useRandomId(): string {
    return useMemo(() => {
        const min = 1;
        const max = 1000000;

        return `random-id-${Math.floor(Math.random() * (max - min + 1) + min)}`;
    }, []);
}
