import {
    createStyles, makeStyles,
    Tab as MaterialTab, TabProps, Theme, withStyles,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React, { FunctionComponent } from 'react';
import { LinkProps, Link as RouterLink } from 'react-router-dom';

type StyledTabProps = TabProps & LinkProps;

const StyledTab = withStyles((theme: Theme) => createStyles({
    root: {
        textTransform: 'none',
        minWidth: 'auto',
        minHeight: '50px',
        padding: `0 ${theme.spacing(2)}px`,
        marginRight: theme.spacing(6),
        overflow: 'visible',
        color: theme.palette.grey[200],
        '&:hover': {
            color: theme.palette.grey[400],
            opacity: 1,
        },
        '&$selected': {
            color: theme.palette.primary.main,
            '& > span > span': {
                color: 'white',
                backgroundColor: theme.palette.primary.main,
            },
        },
        '&:focus': { color: theme.palette.primary.main },
    },
    selected: {},
    wrapper: {
        justifyItems: 'baseline',
        alignItems: 'flex-start',
        fontSize: '1em',
    },
}))((props: StyledTabProps) => <MaterialTab disableRipple {...props} />);

export interface NumberedTabProps {
    number: number | null;
    loading?: boolean;
    url: string;
    label: string;
    index: number;
    selectedIndex: number;
}

const useStyles = makeStyles(theme => createStyles({
    counter: {
        backgroundColor: theme.palette.grey[100],
        color: theme.palette.grey[200],
        width: '2em',
        padding: '2px 0',
        borderRadius: '100%',
        textAlign: 'center',
        position: 'absolute',
        right: '-32px',
    },
}));

const NumberedTab: FunctionComponent<NumberedTabProps> = (
    { number, loading, url, label, index, selectedIndex },
) => {
    const classes = useStyles();

    const icon = (() => {
        if (loading) {
            return (
                <Skeleton
                    variant="circle"
                    width="36x"
                    height="29px"
                    className={classes.counter}
                />
            );
        }

        return (
            <span className={classes.counter}>
                {number ?? '?'}
            </span>
        );
    })();

    return (
        <StyledTab
            disableRipple
            icon={icon}
            component={RouterLink}
            to={url}
            label={label}
            selected={selectedIndex === index}
            id={`tab-${index}`}
            aria-controls={`tabpanel-${index}`}
        />
    );
};

export default NumberedTab;
