import {
    AppBar, Grid, Toolbar, useMediaQuery,
    useTheme, createStyles, makeStyles, Theme,
} from '@material-ui/core';
import React from 'react';
import BackToStartButton from './BackToStartButton';
import NextButton from './NextButton';
import PreviousButton from './PreviousButton';

export interface PaginationControlsProps {
    onNextClick: () => void;
    onPreviousClick: () => void;
    onBackToStartClick: () => void;
    hasNext: boolean;
    hasPrevious: boolean;
}

const useStyles = makeStyles((theme: Theme) => createStyles(
    {
        mobileAppBar: {
            backgroundColor: theme.palette.common.white,
            bottom: 0,
            color: theme.palette.common.black,
            padding: theme.spacing(0),
            top: 'auto',
            '& [class*="MuiButton-root"]': {
                minWidth: '40px',
                padding: '5px 13px',
            },
            '& [class*="MuiToolbar-gutters"]': { paddingRight: 0 },
        },
    },
));

// TODO: Remove in favor of TableHeader

/**
 * @deprecated Use TableHeader instead
 */
const PaginationControls = (
    {
        onNextClick,
        onPreviousClick,
        hasNext,
        hasPrevious,
        onBackToStartClick,
    }: PaginationControlsProps,
): JSX.Element => {
    const theme = useTheme();
    const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
    const classes = useStyles();

    const content = (
        <>
            <Grid item xs={mobileView}>
                <Grid container justify="flex-end">
                    <nav
                        role="navigation"
                        aria-label="Pagination"
                        data-testid="pagination"
                    >
                        <BackToStartButton
                            onClick={onBackToStartClick}
                            disabled={!hasPrevious}
                        />
                        <PreviousButton
                            onClick={onPreviousClick}
                            disabled={!hasPrevious}
                        />
                        <NextButton
                            onClick={onNextClick}
                            disabled={!hasNext}
                        />
                    </nav>
                </Grid>
            </Grid>
        </>
    );

    if (mobileView) {
        return (
            <AppBar position="fixed" className={classes.mobileAppBar}>
                <Toolbar>
                    <Grid
                        container
                        direction="row"
                        justify="space-between"
                        alignItems="center"
                    >
                        {content}
                    </Grid>
                </Toolbar>
            </AppBar>
        );
    }

    return (
        <Grid
            container
            justify="flex-end"
            alignItems="center"
            style={{ margin: '0.75em 0 0.25em' }}
        >
            {content}
        </Grid>
    );
};

export default PaginationControls;
