/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { createMuiTheme } from '@material-ui/core';

const GET_E_THEME = '#073690';

export const TOOLBAR_HEIGHT = 56;

const palette = {
    primary: {
        light: '#4E5EBF',
        main: GET_E_THEME,
        dark: '#001261',
    },
    secondary: {
        main: '#F86909',
        dark: '#BE3800',
    },
    error: { main: '#B72323' },
    success: { main: '#12944A' },
    common: { black: '#13263B' },
    grey: {
        100: '#EEF3F9',
        200: '#788B9B',
        300: '#BCBDCB',
        400: '#13263B',
        500: '#E2E2E2',
    },
};

const theme = createMuiTheme({
    overrides: {
        MuiTab: {
            root: {
                fontSize: '1em',
                fontWeight: 600,
            },
            textColorInherit: { opacity: 1 },
            selected: {},
        },
        MuiContainer: { root: { '& [class*="MuiSkeleton-root"]': { backgroundColor: '#EEF3F9' } } },
        MuiDialog: {
            container: { width: '100%' },
            paperWidthSm: {
                maxWidth: '425px',
                width: '100%',
            },
        },
        MuiInputBase: {
            root: {
                '&[class*="MuiFilledInput-root"]': {
                    backgroundColor: '#f8f8f8',
                    '&:hover': { backgroundColor: 'rgb(0 0 0 / 6%)' },
                },
            },
        },
        MuiPaper: {
            elevation4: {
                boxShadow: 'none',
                borderBottom: '2px solid #EEEEEE',
            },
            root: { color: '#13263B' },
        },
        MuiButton: {
            root: {
                fontSize: '1em',
                textTransform: 'none',
                padding: '0.5em 1.25em',
                fontWeight: 600,
                color: '#13263B',
            },
            outlined: {
                fontSize: '1em',
                padding: '0.45em 1.25em',
            },
            contained: {
                boxShadow: 'none',
                '&:active': { boxShadow: 'none !important' },
                '&:hover': { boxShadow: 'none !important' },
                '&:focus': { boxShadow: 'none !important' },
                '&[class*="Mui-disabled"]': {
                    backgroundColor: '#EEF3F9',
                    color: '#788B9B',
                },
            },
            containedSecondary: { color: 'white' },
            text: { padding: '0.5em 1.25em' },
        },
        MuiToolbar: {
            regular: {
                height: TOOLBAR_HEIGHT,
                minHeight: TOOLBAR_HEIGHT,
                '@media (min-width: 600px)': {
                    height: TOOLBAR_HEIGHT,
                    minHeight: TOOLBAR_HEIGHT,
                },
            },
            gutters: {
                paddingLeft: '1em',
                paddingRight: '1em',
                '@media (min-width: 600px)': {
                    paddingLeft: '2em',
                    paddingRight: '2em',
                },
            },
        },
        MuiFormHelperText: {
            root: {
                fontSize: '.75em',
                marginTop: 0,
                marginBottom: 4,
            },
        },
        MuiListItem: {
            gutters: {
                paddingLeft: '12px',
                paddingRight: '12px',
            },
        },
        MuiLink: {
            root: {
                color: '#4E5EBF',
                textDecoration: 'none',
                paddingBottom: '1px',
                border: '1px solid transparent',
                '&:hover': {
                    color: GET_E_THEME,
                    textDecoration: 'none',
                    borderBottom: '1px solid',
                },
            },
            underlineNone: {
                '&:hover': {
                    textDecoration: 'none',
                    borderBottom: 'none',
                    paddingBottom: 0,
                },
            },
            underlineHover: {
                '&:hover': {
                    textDecoration: 'none',
                    borderBottom: '1px solid',
                },
            },
        },
        MuiListItemIcon: { root: { minWidth: '36px' } },
        MuiDrawer: {
            paper: {
                backgroundColor: GET_E_THEME,
                color: 'white',
                '& [class*="MuiSvgIcon-root"]': { color: 'white' },
                '& [class*="MuiIconButton-root"]': { padding: 0 },
            },
        },
        MuiTypography: { colorTextPrimary: { color: '#13263B' } },
        MuiMenuItem: { root: { fontSize: '1em' } },
        MuiInput: {
            underline: {
                '&:before': { borderBottom: '1px solid #E2E2E2' },
                '&:hover:not($disabled):not($focused):not($error):before': { borderBottom: '1px solid #788B9B' },
            },
        },
        MuiTableCell: {
            head: { fontWeight: 600 },
            stickyHeader: { backgroundColor: 'white' },
        },
        MuiTableContainer: {
            root: {
                border: '1px solid #e0e0e0',
                borderRadius: 4,
            },
        },
        MuiDivider: {
            root: {
                backgroundColor: '#EEF3F9',
                height: 2,
            },
        },
    },
    palette,
    typography: {
        fontSize: 16,
        fontFamily: '\'Lato\', sans-serif',
        body1: {
            fontSize: '1rem',
            lineHeight: '19px',
        },
        h1: {
            color: GET_E_THEME,
            fontSize: '1.75rem',
            fontWeight: 'bold',
            marginBottom: '.5em',
        },
        h2: {
            color: GET_E_THEME,
            fontSize: '1.4rem',
            lineHeight: '29px',
            fontWeight: 'bold',
            marginBottom: '.5em',
        },
        h3: {
            color: GET_E_THEME,
            fontSize: '1.125em',
            fontWeight: 'bold',
            margin: '0 0 .5rem 0',
        },
        h4: {
            textTransform: 'uppercase',
            color: '#788B9B',
            fontSize: '.875rem',
            fontWeight: 'bold',
            marginBottom: '.3em',
        },
    },
});

export default theme;
