import { Button, Hidden, makeStyles } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { supportIcon } from '../images';

const useStyles = makeStyles(theme => ({
    container: {
        ...theme.typography.button,
        backgroundColor: theme.palette.background.paper,
        display: 'inline-block',
        textAlign: 'right',
        '& [class*="MuiButton-label"]': {
            fontWeight: theme.typography.fontWeightRegular,
        },
        '& [class*="MuiButton-text"]': {
            padding: '0.5em 0',
            minWidth: 'unset',
        },
        textTransform: 'none',
    },
    button: { padding: `${theme.spacing(1, 2)} !important` },
    icon: { [theme.breakpoints.up('sm')]: { margin: '0 0 0 .5em' } },
}));

export interface SupportButtonProps {
    onClick: () => void;
    children: string;
}

const SupportButton: FunctionComponent<SupportButtonProps> = ({
    onClick, children,
}) => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <Button
                className={classes.button}
                onClick={onClick}
            >
                <Hidden xsDown>
                    {children}
                </Hidden>

                <img
                    src={supportIcon}
                    alt={children}
                    className={classes.icon}
                />
            </Button>
        </div>
    );
};

export default SupportButton;
