import { Button } from '@material-ui/core';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import React from 'react';

const SkipToEndButton = (
    { disabled, onClick }: {
        disabled: boolean;
        onClick: () => void;
    },
): JSX.Element => (
    <Button
        onClick={onClick}
        disabled={disabled}
    >
        <SkipNextIcon fontSize="small"/>
    </Button>
);

export default SkipToEndButton;
