import {
    AppBar, Grid, IconButton, Slide, Toolbar, useScrollTrigger,
} from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import clsx from 'clsx';
import React, { FunctionComponent } from 'react';
import useTopBarStyles from './TopBar.styles';
import useTopBarMobileStyles from './TopBarMobile.styles';

export interface MobileTopBarProps {
    menuClosed: boolean;
    onMenuOpen: () => void;
}

const TopBarMobile: FunctionComponent<MobileTopBarProps> = ({
    menuClosed,
    onMenuOpen,
    children,
}) => {
    const trigger = useScrollTrigger();
    const topBarStyles = useTopBarStyles();
    const classes = useTopBarMobileStyles();

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            <AppBar
                position="fixed"
                className={clsx(
                    topBarStyles.appBar,
                    { [topBarStyles.appBarShift]: !menuClosed },
                )}
            >
                <Toolbar>
                    <IconButton
                        color="default"
                        aria-label="open drawer"
                        onClick={() => onMenuOpen()}
                        data-testid="open-menu-button"
                        edge="start"
                        className={
                            clsx(classes.menuButton, { [classes.hide]: !menuClosed })
                        }
                    >
                        <ChevronRightIcon />
                    </IconButton>
                    <Grid
                        container
                        justify="flex-end"
                        alignItems="center"
                    >
                        {children}
                    </Grid>
                </Toolbar>
            </AppBar>
        </Slide>
    );
};

export default TopBarMobile;
