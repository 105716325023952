import { Grid, useMediaQuery, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import React, { FunctionComponent, ReactNode } from 'react';
import MobileTopBar from './MobileTopBar';
import NavigationBar from './NavigationBar';
import useNavigationBarLayoutStyles from './NavigationBarLayout.styles';
import TopBar from './TopBar';

export interface NavigationBarLayoutProps {
    closed: boolean;
    onMenuClose: () => void;
    onMenuOpen: () => void;

    /**
     * @deprecated Use `environmentBadge` instead.
     */
    isDevEnvironment?: boolean;

    /**
     * Text to show as a badge to indicate the current environment.
     * Should not be used on production.
     */
    environmentBadge?: string;
    breadcrumbs?: ReactNode;
    actions?: ReactNode;
    navigationList: ReactNode;
    navigationBarFooter?: ReactNode;
}

const NavigationBarLayout: FunctionComponent<NavigationBarLayoutProps> = ({
    closed,
    onMenuClose,
    onMenuOpen,
    isDevEnvironment,
    environmentBadge,
    breadcrumbs,
    navigationList,
    children,
    navigationBarFooter,
    actions,
}) => {
    const theme = useTheme();
    const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
    const classes = useNavigationBarLayoutStyles();

    return (
        <>
            {mobileView
                ? (
                    <MobileTopBar menuClosed={closed} onMenuOpen={onMenuOpen}>
                        {breadcrumbs}
                    </MobileTopBar>
                )
                : (
                    <TopBar closed={closed}>
                        <Grid
                            container
                            justify="space-between"
                            alignItems="center"
                        >
                            <Grid item>{breadcrumbs}</Grid>
                            <Grid item>{actions}</Grid>
                        </Grid>
                    </TopBar>
                )
            }
            <NavigationBar
                closed={closed}
                onMenuClose={onMenuClose}
                onMenuOpen={onMenuOpen}
                isDevEnvironment={isDevEnvironment}
                environmentBadge={environmentBadge}
                footer={navigationBarFooter}
            >
                {navigationList}
            </NavigationBar>

            <main
                className={
                    clsx(
                        classes.contentContainer,
                        { [classes.contentShift]: !closed },
                    )
                }
            >
                <div className={classes.content}>
                    {children}
                </div>
            </main>
        </>
    );
};

export default NavigationBarLayout;

