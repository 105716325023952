import { Button } from '@material-ui/core';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import React from 'react';

const BackToStartButton = (
    { disabled, onClick }: {
        disabled: boolean;
        onClick: () => void;
    },
): JSX.Element => (
    <Button
        onClick={onClick}
        disabled={disabled}
        data-testid="skip-previous-button"
    >
        <SkipPreviousIcon fontSize="small"/>
    </Button>
);

export default BackToStartButton;
