import { Grid, makeStyles } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import Footer from './Footer';
import TopBar from './TopBar';

const useStyles = makeStyles({
    root: { minHeight: '100vh' },
    content: {
        backgroundColor: '#EEF3F9',
        padding: '2rem',
    },
});

const SignedOutLayout: FunctionComponent = ({ children }) => {
    const classes = useStyles();

    return (
        <Grid
            container
            direction="column"
            justify="center"
            alignItems="stretch"
            className={classes.root}
        >
            <Grid item>
                <TopBar/>
            </Grid>

            <Grid item xs className={classes.content}>
                {children}
            </Grid>

            <Grid item>
                <Footer/>
            </Grid>
        </Grid>
    );
};

export default SignedOutLayout;
