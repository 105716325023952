import {
    Dialog,
    DialogProps,
    IconButton,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React, { FunctionComponent } from 'react';
import useModalStyles from './Modal.styles';

export interface ModalProps {
    open: boolean;
    onClose: () => void;
    maxWidth?: DialogProps['maxWidth'];
}

const Modal: FunctionComponent<ModalProps> = (
    { children, open, onClose, maxWidth },
) => {
    const theme = useTheme();
    const modalClasses = useModalStyles();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

    return (
        <Dialog
            open={open}
            fullScreen={fullScreen}
            onClose={onClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className={modalClasses.root}
            maxWidth={maxWidth}
        >
            <IconButton
                aria-label="close"
                className={modalClasses.closeButton}
                onClick={onClose}
                data-testid="close-button"
            >
                <CloseIcon />
            </IconButton>

            <div className={modalClasses.content}>
                {children}
            </div>
        </Dialog>
    );
};

export default Modal;
