import {
    makeStyles, TableBody as MuiTableBody,
    TableBodyProps as MuiTableBodyProps,
} from '@material-ui/core';
import clsx from 'clsx';
import React, { FunctionComponent } from 'react';

export type TableBodyProps = MuiTableBodyProps & {loading?: boolean};

const useStyles = makeStyles({
    root: {
        transition: 'opacity .5s',
    },
    loading: {
        opacity: 0.4,
    },
});

const TableBody: FunctionComponent<TableBodyProps> = ({ loading, ...otherProps }) => {
    const classes = useStyles();

    return (
        <MuiTableBody
            {...otherProps}
            className={
                clsx(
                    otherProps.className,
                    classes.root,
                    { [classes.loading]: loading },
                )
            }
        />
    );
};

export default TableBody;
