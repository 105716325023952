import {
    makeStyles, Stepper as MuiStepper, StepperProps as MuiStepperProps,
} from '@material-ui/core';
import clsx from 'clsx';
import React, { FunctionComponent } from 'react';
import StepConnector from './StepConnector';

const useStyles = makeStyles({ root: { background: 'none' } });

export interface StepperProps {
    activeStep: number;
    className?: string;
    children: MuiStepperProps['children'];
}

const Stepper: FunctionComponent<StepperProps> = ({
    activeStep, className, children,
}) => {
    const classes = useStyles();

    return (
        <MuiStepper
            alternativeLabel
            activeStep={activeStep}
            connector={<StepConnector />}
            className={clsx(classes.root, className)}
        >
            {children}
        </MuiStepper>
    );
};

export default Stepper;
