import { Button, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React, { FunctionComponent, MouseEventHandler } from 'react';

export interface CompactButtonProps {
    onClick: MouseEventHandler;
    children: string;
    disabled?: boolean;
    className?: string;
}

const useStyles = makeStyles(() => ({
    root: {
        backgroundColor: '#ffebd7',
        color: '#BE3800',
        padding: '.25rem .75rem',
        '&:hover': { backgroundColor: '#ffe3c7' },
    },
}));

const CompactButton: FunctionComponent<CompactButtonProps> = (
    { children, onClick, disabled, className },
) => {
    const classes = useStyles();

    return (
        <Button
            color="secondary"
            disabled={disabled}
            onClick={onClick}
            type="button"
            variant="contained"
            className={clsx(classes.root, className)}
        >
            {children}
        </Button>
    );
};

export default CompactButton;
