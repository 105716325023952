import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React, { FunctionComponent } from 'react';
import useLoaderDebounce from '../helpers/useLoaderDebounce';
import Spinner from '../Spinner';

const useStyles = makeStyles(theme => ({
    root: {
        color: '#828282',
        fontWeight: 600,
        fontSize: '1rem',
        background: 'none',
        border: 'none',
        padding: 0,
        outline: 'none',
        fontFamily: theme.typography.fontFamily,
        cursor: 'pointer',
    },
    disabled: {
        color: '#b8b8b8',
    },
    spinner: {
        verticalAlign: 'middle',
        marginLeft: '.5rem',
    },
    text: {
        verticalAlign: 'middle',
    },
}));

export interface TertiaryButtonProps {
    onClick: () => void;
    className?: string;
    loading?: boolean;
    disabled?: boolean;
}

const TertiaryButton: FunctionComponent<TertiaryButtonProps> = props => {
    const classes = useStyles();
    const showSpinner = useLoaderDebounce(props.loading ?? false);

    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    const disabled = props.disabled || props.loading;

    return (
        <button
            onClick={props.onClick}
            className={clsx(
                classes.root,
                { [classes.disabled]: disabled },
                props.className,
            )}
            type="button"
            disabled={disabled}
        >
            <span className={classes.text}>
                {props.children}
            </span>

            {
                showSpinner
                    ? (
                        <Spinner
                            size={20}
                            className={classes.spinner}
                        />
                    )
                    : null
            }
        </button>
    );
};

export default TertiaryButton;
