import {
    makeStyles, TableContainer as MuiTableContainer,
    TableContainerProps as MuiTableContainerProps,
} from '@material-ui/core';
import clsx from 'clsx';
import React, { FunctionComponent } from 'react';

type TableContainerProps = MuiTableContainerProps & {noFooter?: boolean};

const useStyles = makeStyles({
    noFooter: {
        borderBottom: 'none',
    },
});

const TableContainer: FunctionComponent<TableContainerProps> = (
    { noFooter, ...otherProps },
) => {
    const classes = useStyles();

    return (
        <MuiTableContainer
            {...otherProps}
            className={
                clsx(
                    otherProps.className,
                    { [classes.noFooter]: noFooter },
                )
            }
        />
    );
};

export default TableContainer;
