import { Button, makeStyles } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import clsx from 'clsx';
import copy from 'copy-to-clipboard';
import React, { FunctionComponent, useEffect, useState } from 'react';

const useStyles = makeStyles(theme => ({
    clickable: { cursor: 'pointer' },
    buttonText: { verticalAlign: 'middle' },
    button: {
        padding: '0 0 0 .25em',
        margin: 0,
        minWidth: 0,
        backgroundColor: 'transparent',
        color: theme.palette.grey[300],
        '&:hover': {
            backgroundColor: 'transparent',
            color: theme.palette.grey[200],
        },
    },
    invisible: { visibility: 'hidden' },
    icon: { fontSize: '1.2em' },
    doneIcon: { color: `${theme.palette.success.main} !important` },
}));

export type CopyableProps = {
    clickable?: boolean;
} & ({
    value: string;
} | {
    children: string;
    value?: string;
});

const Copyable: FunctionComponent<CopyableProps> = props => {
    const classes = useStyles();

    const [showingButton, setShowingButton] = useState(false);
    const [showingCopied, setShowingCopied] = useState(false);

    const value = props.value ?? props.children;

    if (typeof value !== 'string') {
        throw new Error('Value is required when children is not a string');
    }

    useEffect(() => {
        if (!showingCopied) {
            return;
        }

        const handle = setTimeout(() => {
            setShowingCopied(false);
        }, 2000);

        return () => clearTimeout(handle);
    }, [showingCopied]);

    const handleClick = (): void => {
        copy(value);
        setShowingCopied(true);
    };

    return (
        <span
            className={clsx({ [classes.clickable]: props.clickable })}
            onMouseOver={() => setShowingButton(true)}
            onMouseLeave={() => setShowingButton(false)}
            onClick={() => {
                if (props.clickable) {
                    handleClick();
                }
            }}
        >
            <span className={classes.buttonText}>
                {props.children}
            </span>

            <Button
                className={clsx(
                    classes.button,
                    { [classes.invisible]: !showingButton && !showingCopied },
                )}
                disableRipple
                onClick={handleClick}
            >
                {
                    showingCopied
                        ? <DoneIcon className={clsx(classes.icon, classes.doneIcon)} />
                        : <FileCopyIcon className={classes.icon} />
                }
            </Button>
        </span>
    );
};

export default Copyable;
