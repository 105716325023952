import { createStyles, makeStyles } from '@material-ui/core';

const navigationLogoStyles = makeStyles(() => createStyles({
    smallLogo: {
        width: '23px',
        margin: '1em auto',
        display: 'block',
    },
    largeLogo: {
        width: '100%',
        maxWidth: '100px',
        margin: '1em auto',
        display: 'block',
    },
    companyInformation: {
        fontSize: '0.75em',
        color: 'white',
        textAlign: 'center',
        margin: '0',
    },
    companyInformationContainer: {
        margin: '1em 0',
        width: '100%',
    },
}));

export default navigationLogoStyles;
